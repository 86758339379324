<template>
  <div class="UserConfig">
    <h2>用户管理</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('UserConfigAdd')">添加</el-button>
    <div class="searchWrap">
      <span>昵称</span>
      <el-input
        class="searchInput"
        size="small"
        placeholder="请输入内容"
        v-model="name"
        clearable
      >
      </el-input>
      <span>帐号</span>
      <el-input
        class="searchInput"
        size="small"
        placeholder="请输入内容"
        v-model="account"
        clearable
      >
      </el-input>
      <span>企业</span>
      <el-select
        v-model="deptId"
        clearable
        size="small"
        placeholder="请选择"
        class="searchInput"
      >
        <el-option
          v-for="item in deptList"
          :key="item.id"
          :label="item.fullName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="small" @click="onGetListData"
        >搜索</el-button
      >
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="tenantId" label="租户ID"> </el-table-column>
      <el-table-column prop="code" label="用户编号"> </el-table-column>
      <el-table-column prop="account" label="帐号"> </el-table-column>
      <el-table-column prop="name" label="昵称"> </el-table-column>
      <el-table-column prop="roleName" label="角色名"> </el-table-column>
      <el-table-column prop="fullName" label="企业"> </el-table-column>
      <el-table-column align="right" width="310">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDetail(scope.row)" :disabled="onCheckBtnAuthority('UserConfigDetail')">查看</el-button>
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('UserConfigEdit')">编辑</el-button>
          <el-button size="mini" @click="handlePass(scope.row)" :disabled="onCheckBtnAuthority('UserConfigReset')">密码重置</el-button>
          <el-button size="mini" type="danger" @click="handleDel(scope.row)" :disabled="onCheckBtnAuthority('UserConfigDel')"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="基本信息"
      :visible.sync="showDialog"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm" label-width="80px">
        <el-form-item label="用户编号" prop="">
          <el-input v-model="formData.code" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="formData.account" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item>
        <!-- <el-form-item label="密码" prop="password">
          <el-input v-model="formData.password" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item> -->
        <el-form-item label="昵称" prop="name">
          <el-input v-model="formData.name" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item>
        <el-form-item label="真名" prop="realName">
          <el-input v-model="formData.realName" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="formData.email" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="formData.phone" :disabled="formDataType == 'Detail'"></el-input>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
            style="width: 100%;"
            v-model="formData.birthday"
            value-format="yyyy-MM-dd hh:ss:mm"
            type="date"
            :disabled="formDataType == 'Detail'"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="formData.sex" :disabled="formDataType == 'Detail'">
            <el-radio :label="0">女</el-radio>
            <el-radio :label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="企业" prop="deptId">
          <el-select
            v-model="formData.deptId"
            clearable
            style="width: 100%;"
            placeholder="请选择"
            class="searchInput"
            :disabled="formDataType != 'Add'"
          >
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select
              v-model="formData.roleId"
              clearable
              placeholder="请选择"
              style="width: 100%;"
              :disabled="formDataType == 'Detail'"
            >
              <el-option
                v-for="item in roleList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="绑定项目" prop="projectIds">
          <el-checkbox-group v-model="formData.projectIds" :disabled="formDataType == 'Detail'">
              <el-row v-for="item in projectList" :key="item.projectId"><el-checkbox :label="item.projectId">{{item.projectName}}</el-checkbox></el-row>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" v-if="formDataType != 'Detail'">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.UserConfig {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "UserConfig",
  components: {},
  data() {
    return {
      pageBtn: [],

      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      name: "",
      account: "",
      deptId: "",
      showDialog: false,
      formDataType: null,
      formData: {
        id: null, // ID
        code: null, // 用户编号
        account: null, // 账号
        name: null, // 昵称
        realName: null, // 真名
        avatar: null, // 头像
        email: null, // 邮箱
        phone: null, // 手机
        birthday: null, // 生日
        sex: 1, // 性别(0女1男)
        roleId: null, // 角色id
        deptId: null, // 部门id
        status: 0, // 状态(0正常1锁定)默认0
        projectIds: [],
      },
      formRules: {
        account: [{ required: true, message: "必填项", trigger: "blur" }],
        roleId: [{ required: true, message: "必填项", trigger: "blur" }],
        deptId: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      roleList: [],
      deptList: [],
      projectList: []
    };
  },
  computed: {},
  watch: {
    deptId: function () {
      this.pageNum = 1
      this.onGetListData()
    },
    'formData.deptId': {
      handler: function (val) {
        
        if (val) {
          this.onGetProjectList()
          this.onGetRoleList()
        } else {
          this.projectList = []
          this.roleList = []
        }
        if (this.formDataType == 'Add') {
          this.formData.roleId = null
          this.formData.projectIds = []
        }
      },
    }
  },
  mounted() {
    this.onGetPageBtn()
    this.onGetListData();
    this.onGetDeptListData();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1680748194148425729",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        deptId: this.deptId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        name: this.name,
        account: this.account,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/sysUser/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          }else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 添加
    handleAdd: function () {
      this.formData.id = null
      this.formData.code = null
      this.formData.account = null
      this.formData.name = null
      this.formData.realName = null
      this.formData.avatar = null
      this.formData.email = null
      this.formData.phone = null
      this.formData.birthday = null
      this.formData.sex = 1
      this.formData.roleId = null
      this.formData.deptId = null
      this.formData.status = null
      this.formData.projectIds = []

      this.showDialog = true;
      this.formDataType = 'Add'
    },
    // 编辑
    handleEdit: function (row) {
      this.formData.id = row.id
      this.formData.code = row.code
      this.formData.account = row.account
      this.formData.name = row.name
      this.formData.realName = row.realName
      this.formData.avatar = row.avatar
      this.formData.email = row.email
      this.formData.phone = row.phone
      this.formData.birthday = row.birthday
      this.formData.sex = row.sex
      this.formData.roleId = row.roleId
      this.formData.deptId = row.deptId
      this.formData.status = row.status
      this.formData.projectIds = row.projectIds

      this.showDialog = true;
      this.formDataType = 'Edit'
    },
    // 查看
    handleDetail: function (row) {
      this.formData.id = row.id
      this.formData.code = row.code
      this.formData.account = row.account
      this.formData.name = row.name
      this.formData.realName = row.realName
      this.formData.avatar = row.avatar
      this.formData.email = row.email
      this.formData.phone = row.phone
      this.formData.birthday = row.birthday
      this.formData.sex = row.sex
      this.formData.roleId = row.roleId
      this.formData.deptId = row.deptId
      this.formData.status = row.status
      this.formData.projectIds = row.projectIds

      this.showDialog = true;
      this.formDataType = 'Detail'
    },
    // 关闭
    handleClose: function () {
      this.formData.id = null
      this.formData.code = null
      this.formData.account = null
      this.formData.name = null
      this.formData.realName = null
      this.formData.avatar = null
      this.formData.email = null
      this.formData.phone = null
      this.formData.birthday = null
      this.formData.sex = 1
      this.formData.roleId = null
      this.formData.deptId = null
      this.formData.status = null
      this.formData.projectIds = []

      this.showDialog = false;
      this.$refs['ruleForm'].resetFields();
    },
    // 提交
    handleSubmit: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/sysUser/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
            that.onGetListData()
            that.handleClose()
          }else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDel: function (row) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDel(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDel: function (row) {
      var that = this;
      var data = {
        id: row.id
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/sysUser/remove",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
            that.onGetListData()
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取角色列表
    onGetRoleList: function () {
      var that = this;
      var data = {
        deptId: this.formData.deptId
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/role/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            console.log(res);
            var list = []
            res.data.forEach(item => {
              list.push({
                code: item.id,
                name: item.roleName
              })
            })
            that.roleList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取项目列表
    onGetProjectList: function () {
      var that = this;
      var data = {
        deptId: this.formData.deptId
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/project/userProject",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.projectList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置密码
    handlePass: function (row) {
      this.$confirm("此操作将重置该用户密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onPass(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行重置密码
    onPass: function (row) {
      var that = this;
      var data = {
        id: row.id
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/sysUser/resetPassword",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取组织机构列表
    onGetDeptListData: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/dept/getDept",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.code == 200 && res.success == true) {
            that.deptList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    